.group {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 40px;

    img {
        width: 350px;
        border-radius: 10px;
        object-fit: cover;
        transform: rotate(1.2deg);

        &.group-image-left {
            margin-top: 0;
            transform: rotate(-1.2deg);
        }
    }

    > {
        grid-column: 1;
    }
}